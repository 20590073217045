export function formatTwoDecimal(value: number): string | number {
  return formatDecimal(value, 2);
}

export function formatPercentage(value: number): string | number {
  if (value === null || isNaN(value))
    return value;

  return `${formatDecimal(value, 2)}%`
}

export function formatDecimal(
  value: number,
  rounding: number = 2
): string | number {
  return isNaN(value) || value == null
    ? value
    : Number(value).toFixed(rounding);
}

export function formatFinancialAmounts(value: number): string | number {
  return isNaN(value) || value == null
    ? value
    : Number(value).toLocaleString("en-US", { minimumFractionDigits: 0 });
}
export function formatFinancialAmountsFractions(
  value: number
): string | number {
  return isNaN(value) || value == null
    ? value
    : Number(value).toLocaleString("en-US", { maximumFractionDigits: 2 });
}
export function formatWithPrecision(value: number, fieldRoundingPrecision: number): string | number {
  return value == null || isNaN(parseFloat(value.toString().replace(",","."))) || value.toString() == ""
      ? value
      : Number(parseFloat(value.toString().replace(",","."))).toLocaleString("en-US", { minimumFractionDigits: fieldRoundingPrecision ? fieldRoundingPrecision : 0 });
}
